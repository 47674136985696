@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

.my-table th,
.my-table td {
  padding: 10px;
  text-align: left;
}

.my-table th:first-child,
.my-table td:first-child {
  padding: 10px 0;
  text-align: center;
}

.my-table th:first-child input {
  margin-right: 5px;
}

.my-table td:first-child input {
  margin-right: 5px;
}

.my-table td {
  border-bottom: 1px solid #ccc; /* Add a separation line for rows */
}

.my-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  border-bottom: 1px solid #ccc; /* Add a separation line for rows */
}

.my-table tr:last-child td {
  border-bottom: none; /* Remove the separation line for the last row */
}

.PhoneInputInput {
  height: 2.3rem !important;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.25rem;
  box-sizing: border-box;
  font-size: 1rem;
  width: 100%;
  color: #000;
  display: block;
  text-align: left;
  border: 0.0625rem solid #ced4da;
  background-color: #fff;
  -webkit-transition: border-color 100ms ease;
  transition: border-color 100ms ease;
  min-height: 2.625rem;
  padding-left: calc(2.625rem / 3);
  padding-right: calc(2.625rem / 3);
}

.items {
  margin: 1rem;
}

.page-link {
  color: black;
}
.page-item.active .page-link {
  background-color: dimgray;
  border-color: dimgray;
}
