.sharering-query {
  width: 200px;
  height: 200px;
}

.sharering-query .qrcode-content {
  width: 100%;
  height: 100%;
}

.sharering-query #qrcode {
  width: 100%;
  height: 100%;
}
