* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Header part */

.headerPart {
  width: calc(100% - 150px);
  margin-left: auto;
  height: auto;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
}

.headerPart.sticky {
  position: fixed;
  top: 0;
  left: 150px;
  right: 0;
  width: calc(100% - 150px);
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.headerInn {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 8px; */
}

.headerInn i {
  color: #000;
  font-size: 15px;
}

.headerUl {
  list-style-type: none;
  display: flex;
  gap: 15px;
}

.headerUl li {
  width: 150px;
  background-color: #fff;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.3);
}

.headerUl li a {
  align-items: center;
  color: #000;
  display: block;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  padding: 5px;
}

.headerUl li a:hover {
  font-size: 12px;
}

.headerUl li a.active {
  background-color: #cccccc;
}

.headerUl li:hover {
  background-color: #cccccc;
}

.headmenuBtn {
  display: none;
}

.allAmntPara {
  font-size: 16px;
  font-style: italic;
  color: #151515;
  font-weight: 700;
  margin: 0;
}

.allAmntParaDiv {
  text-align: right;
  margin-bottom: 3px;
}

.btmheader {
  padding-bottom: 3px;
}

.headerPara {
  display: flex;
  justify-content: flex-end;
  color: #000;
  font-size: 11px;
  font-weight: 500;
  font-style: italic;
}

/* sidebar */

.sidebarPart {
  width: 150px;
  height: 100vh;
  background-color: #e9e9e9;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  box-shadow: 1px 10px 15px 0px rgba(0, 0, 0, 0.8);
  padding: 10px 15px;
  transition: 0.6s;
}

.Logo {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.Logo img {
  width: 100%;
  height: 100%;
}

.sideTopHead {
  color: #000;
  font-size: 13px;
  text-align: center;
}

.todayDate {
  padding: 30px 0px;
  text-align: center;
}

.datehead {
  color: #605e5c;
  font-size: 12px;
  margin-bottom: 6px;
}

.datepara {
  color: #000;
  font-size: 11px;
  font-weight: 500;
}

.datefilter {
  width: 100%;
  display: block;
  border: none;
  outline: 0;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.3);
  color: #000;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
}

.datefilter:hover {
  color: #000;
}

.DateDrop {
  background-color: #e6e6e6;
  margin: 15px 0px 30px;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.5);
  padding: 5px;
  position: relative;
}

.DateDrop p {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.eraserTooltip {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 3px 0;
  position: absolute;
  top: -30px;
  z-index: 1;
  font-size: 12px;
  left: -40px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
}

.eraserTooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltiptext::before {
  content: "";
  position: absolute;
  bottom: -20px;
  display: block;
  height: 22px;
  left: 50%;
  transform: translateX(-50%);
  border-color: #fffefe transparent transparent transparent;
  border-style: solid;
  border-width: 8px;
}

.DateDrop i {
  color: #858585;
  font-size: 12px;
  opacity: 0;
}

.DateDrop:hover i {
  opacity: 1;
}

.dateselectDiv {
  position: relative;
}

.dateselectBtn {
  background-color: transparent;
  border: 1px solid #fff;
  outline: 0;
  color: #747474;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  padding: 0px 3px;
}

.dateselectBtn i {
  font-size: 11px;
  opacity: 1;
}

.dateSlctDrop {
  padding: 10px 5px;
  position: absolute;
  background: #e9e9e9;
  width: 100%;
  left: 0;
  height: 130px;
  overflow-y: auto;
  z-index: 10;
}

.dateSlctDrop::-webkit-scrollbar {
  display: none;
}

.inputDiv {
  display: flex;
  align-items: baseline;
  gap: 5px;
  margin: 3px 0px;
}

.inputDiv input {
  width: 10px;
  height: 10px;
  accent-color: #000;
}

.inputDiv p {
  font-size: 10px;
}

.sectorFilHead {
  background-color: #efdca9;
  padding: 3px;
  cursor: pointer;
}

.sectorFilHead p {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.FilDropHead {
  font-size: 13px;
  color: #000;
  font-weight: 500;
}

.SecCounFil {
  background-color: #fff;
  margin: 15px 0px 30px;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.5);
  padding: 5px;
  position: relative;
}

.inputPara {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.SecCounFil i {
  color: #858585;
  font-size: 12px;
  opacity: 0;
}

.SecCounFil:hover i {
  opacity: 1;
}

.SecCounDiv {
  position: relative;
}

.SecCounBtn {
  background-color: #f7f4e0;
  border: 1px solid #fff;
  outline: 0;
  color: #747474;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  padding: 0px 3px;
}

.SecCounBtn i {
  font-size: 11px;
  opacity: 1;
}

.SecCounDrop {
  padding: 10px 5px;
  position: absolute;
  bottom: -130px;
  background-color: #f7f4e0;
  width: 100%;
  margin: 0px auto;
  left: 0;
  height: 130px;
  overflow-y: auto;
  z-index: 10;
}

.SecCounDrop::-webkit-scrollbar {
  display: none;
}

/* sidebar */

.sidebarPart {
  width: 150px;
  height: 100vh;
  background-color: #e9e9e9;
  position: fixed;
  left: 0;
  top: 0;
  box-shadow: 1px 10px 15px 0px rgba(0, 0, 0, 0.8);
  padding: 10px 15px;
}

.Logo {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.Logo img {
  width: 100%;
  height: 100%;
}

.sideTopHead {
  color: #000;
  font-size: 13px;
  text-align: center;
}

.todayDate {
  padding: 30px 0px;
  text-align: center;
}

.datehead {
  color: #605e5c;
  font-size: 12px;
  margin-bottom: 6px;
}

.datepara {
  color: #000;
  font-size: 11px;
  font-weight: 500;
}

.datefilter {
  width: 100%;
  display: block;
  border: none;
  outline: 0;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.3);
  color: #000;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
}

.datefilter:hover {
  color: #000;
}

.DateDrop {
  background-color: #e6e6e6;
  margin: 15px 0px 30px;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.5);
  padding: 5px;
  position: relative;
}

.DateDrop p {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.eraserTooltip {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 3px 0;
  position: absolute;
  top: -30px;
  z-index: 1;
  font-size: 12px;
  left: -40px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
}

.eraserTooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltiptext::before {
  content: "";
  position: absolute;
  bottom: -20px;
  display: block;
  height: 22px;
  left: 50%;
  transform: translateX(-50%);
  border-color: #fffefe transparent transparent transparent;
  border-style: solid;
  border-width: 8px;
}

.DateDrop i {
  color: #858585;
  font-size: 12px;
  opacity: 0;
}

.DateDrop:hover i {
  opacity: 1;
}

.dateselectDiv {
  position: relative;
}

.dateSlctDrop {
  padding: 10px 5px;
  position: absolute;
  background: #e9e9e9;
  width: 100%;
  left: 0;
  height: 130px;
  overflow-y: auto;
  z-index: 10;
}

.dateSlctDrop::-webkit-scrollbar {
  display: none;
}

.inputDiv {
  display: flex;
  align-items: baseline;
  gap: 5px;
  margin: 3px 0px;
}

.inputDiv input {
  width: 10px;
  height: 10px;
  accent-color: #000;
}

.inputDiv p {
  font-size: 10px;
}

.sectorFilHead {
  background-color: #efdca9;
  padding: 3px;
  cursor: pointer;
}

.sectorFilHead p {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.FilDropHead {
  font-size: 13px;
  color: #000;
  font-weight: 500;
}

.SecCounFil {
  background-color: #fff;
  margin: 15px 0px 30px;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.5);
  padding: 5px;
  position: relative;
}

.inputPara {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.SecCounFil i {
  color: #858585;
  font-size: 12px;
  opacity: 0;
}

.SecCounFil:hover i {
  opacity: 1;
}

.SecCounDiv {
  position: relative;
}

.SecCounBtn {
  background-color: #f7f4e0;
  border: 1px solid #fff;
  outline: 0;
  color: #747474;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  padding: 0px 3px;
}

.SecCounBtn i {
  font-size: 11px;
  opacity: 1;
}

.SecCounDrop {
  padding: 10px 5px;
  position: absolute;
  bottom: -130px;
  background-color: #f7f4e0;
  width: 100%;
  margin: 0px auto;
  left: 0;
  height: 130px;
  overflow-y: auto;
  z-index: 10;
}

.SecCounDrop::-webkit-scrollbar {
  display: none;
}

/* overall view page start */

.RestPage {
  width: calc(100% - 150px);
  height: auto;
  margin-left: auto;
  position: relative;
  margin-bottom: 50px;
}

/* graph amount */

.graphPart {
  border-top: 14px solid #338f86;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.graphAmtSec {
  width: 40%;
  display: flex;
  gap: 10px;
}

.graphAmtDiv {
  width: 50%;
  background-color: #fff;
  box-shadow: 1px 10px 15px 0px #a5a5a5;
  position: relative;
  height: auto;
  padding: 0px 15px;
}

.graphInner {
  height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.graphAmtDivInn {
  width: 100%;
  position: relative;
  text-align: center;
  margin: 20px 0px;
}

.graphAmtDivInn::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 1px;
  background-color: #2277c4;
}

.graphPoint {
  font-size: 13px;
  color: #424242;
}

.graphNum {
  font-size: 15px;
  color: #9634f3;
  margin: 5px 0px;
}

.graphamtFig {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  width: 63px;
  height: 48px;
}

.graphamtFig img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* graph progress bar */

.overallchart {
  width: 60%;
}

.overallChartPara {
  position: absolute;
  top: -20px;
  right: 20px;
  color: #000;
  font-size: 11px;
  font-weight: 500;
  font-style: italic;
}

.overallChartTop {
  background-color: #fff;
  box-shadow: 1px 10px 15px 0px #a5a5a5;
  padding: 5px 20px 5px 5px;
  margin-top: 5px;
}

.overallChartHead {
  font-size: 14px;
  color: #5a5a5a;
  margin-bottom: 5px;
}

.overallChartUl {
  list-style-type: none;
  padding-top: 15px;
}

.overallChartUl li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}

.chartLiLeft {
  width: 22%;
  position: relative;
}

.chartLiTxt {
  font-size: 11px;
  font-weight: 500;
  text-align: end;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ChartLitooltip {
  position: absolute;
  right: 0;
  top: 100%;
  border: 1px solid black;
  background-color: #f1f0f0;
  z-index: 2;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* visibility: hidden; */
}

.ChartLitooltip p {
  font-size: 12px;
  color: #000;
}

.chartLiTxt:hover .ChartLitooltip {
  visibility: visible;
}

.chartLiRight {
  width: 78%;
}

.overallChartTop .progress {
  background-color: #fff;
  border-radius: 0;
}

.overallChartTop .progress-bar {
  background-color: #f5c4af;
  position: relative;
}

.Chartspan {
  position: absolute;
  right: 5px;
  color: #000;
  font-size: 10px;
  font-weight: 500;
}

.overallChartBtm {
  background-color: #fff;
  box-shadow: 1px 10px 15px 0px #a5a5a5;
  padding: 5px 20px 5px 5px;
  margin-top: 15px;
}

.amtDueBtnDiv {
  display: flex;
  gap: 8px;
}

.amtDueBtn {
  background-color: transparent;
  border: none;
  outline: 0;
  font-size: 11px;
  font-weight: 500;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  gap: 2px;
}

.amtBlankDiv {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.overallChartBtm .progress {
  background-color: #92bbac;
  border-radius: 0;
  position: relative;
}

.overallChartBtm .progress-bar {
  background-color: #f0e199;
  position: relative;
}

.barspan {
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
  color: #000;
  font-size: 9px;
  font-weight: 500;
}

.progspan {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3px;
  color: #000;
  font-size: 9px;
  font-weight: 500;
}

/* overall view page end */

/* Investor Dashboard */
.leftSideDivs {
  width: 49%;
}

.leftSideDivsPart {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.invertorFirstRow {
  /* border-top: 14px solid #338f86; */
  border-top: 6px solid #92bbac;
  padding-top: 8px;
  height: auto;
  display: flex;
  gap: 10px;
}

.invesLeftPart {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 40px 18px;
  box-shadow: 0 0 10px 0 #00000073;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 3px;
}

.titleName {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #151515;
  margin: 0 0 8px 0;
}

.titleNumber {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: #595959;
  margin: 0 0 13px 0;
}

.afterTitleDiv {
  width: 50%;
  border-bottom: 3px solid #3786d5;
  margin: 0 auto;
}

.fireFig {
  width: 50px;
  height: auto;
  margin: 0 auto;
}

.fireFig img {
  width: 100%;
  height: 100%;
}

.apxChrtDiv {
  width: 60%;
  height: auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 #00000073;
}

.apxChrtDiv #SvgjsText1222 {
  font-size: 18px;
  font-family: "Epilogue", sans-serif !important;
}

.apxChrtDiv g text {
  font-size: 11px;
  font-weight: 500;
}

/* return analysis area chart */

.returnAnDiv {
  width: 60%;
  height: auto;
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 #00000073;
  position: relative;
}

.returnAnDiv .apexcharts-menu-icon {
  display: none;
}

.returnAnDiv
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-title {
  background-color: transparent !important;
  border: none !important;
}

.returnAnDiv .apexcharts-tooltip-title {
  margin-bottom: 0 !important;
}

.returnAnDiv .apexcharts-tooltip-series-group {
  padding: 0 6px !important;
}

.returnAnDiv .apexcharts-tooltip {
  border-radius: 0px;
}

.returnAnDiv .apexcharts-xaxistooltip {
  display: none;
}

.snApex .apexcharts-tooltip-marker {
  background-color: #f0e199 !important;
}

.PortApex .apexcharts-tooltip-marker {
  background-color: #efb5b9 !important;
}

.returnAnDiv .react-tabs__tab-list {
  position: absolute;
  right: 5px;
  top: 3px;
  z-index: 2;
  border: none;
  margin: 0;
  display: flex;
  gap: 10px;
}

.returnAnDiv .react-tabs__tab {
  background-color: #ede9cd;
  box-shadow: 1px 2px 4px 0 #0000004d;
  color: #151515;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.returnAnDiv .react-tabs__tab--selected {
  border: none;
  border-radius: 0;
}

.ReturnAnTooltip {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 #0000004d;
  color: #747474;
  font-size: 11px;
  left: 0;
  padding: 8px;
  position: absolute;
  top: -45px;
  visibility: hidden;
  z-index: 10;
  width: 140px;
}

.ReturnAnTooltip:after {
  border: 12px solid #0000;
  border-top-color: #fffefe;
  bottom: -20px;
  content: "";
  display: block;
  height: 22px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.returnAnDiv .react-tabs__tab:hover .ReturnAnTooltip {
  visibility: visible;
}

.apexVerticalTxt {
  writing-mode: vertical-lr;
  position: absolute;
  top: 40%;
  left: 5px;
  transform: translateY(-50%);
  transform: rotate(180deg);
  font-size: 12px;
  color: #000;
  font-weight: 500;
}

.eStateTableDiv {
  background-color: #ffffff;
  padding: 15px;
  box-shadow: 0 0 10px 0 #00000073;
  border-radius: 3px;
}

.eStateHead {
  font-size: 15px;
  font-weight: 600;
  color: #151515;
  margin: 0 0 8px 0;
}

.dataTableDiv {
  width: 100%;
  overflow-x: auto;
}

.dataTableDiv::-webkit-scrollbar {
  height: 5px;
}

.dataTableDiv::-webkit-scrollbar-track {
  background-color: #cccccc;
}

.dataTableDiv::-webkit-scrollbar-thumb {
  background-color: #858585;
}

.dataTableDiv table th {
  background-color: #338f86 !important;
  text-align: center;
  color: #ffffff;
  font-size: 13px;
  border-right: 1px solid #d2dcdb;
  border-bottom: 0 !important;
  white-space: nowrap;
}

.dataTableDiv table th:last-child {
  border-right: 0;
}

.dataTableDiv table tr {
  border-bottom: 0 !important;
}

.dataTableDiv table tr:nth-child(even) {
  background-color: #eeeded;
}

.dataTableDiv table tr:hover {
  background-color: #eeeded;
}

.dataTableDiv table td {
  text-align: center;
  font-size: 12px;
  color: #151515;
  padding: 5px 8px;
  border: 1px solid #e5e5e5;
  font-weight: 500;
  white-space: nowrap;
}

.dataTableDiv table td:hover {
  background-color: #ccc;
}

.dataTableDiv table td:last-child {
  border-right: 0;
}

/* Investment Analysis Page */

.investRow2 {
  /* border-top: 14px solid #338f86; */
  border-top: 6px solid #92bbac;
  /* padding-top: 8px; */
}

.eStateTableDiv .react-tabs__tab-list {
  border: 0;
  margin-bottom: 18px;
  display: flex;
  gap: 15px;
}

.eStateTableDiv .react-tabs__tab {
  width: 140px;
  height: 30px;
  background-color: #f9f5c4;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 1px 2px 4px 0 #0000004d;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  position: relative;
}

.eStateTableDiv .react-tabs__tab:hover .ReturnAnTooltip {
  visibility: visible;
}

.eStateTableDiv .react-tabs__tab:last-child {
  margin-right: 0;
}

.eStateTableDiv .react-tabs__tab--selected {
  border: 0;
  background-color: #f9f5c4;
}

/*.snDataTable table tbody tr:nth-child(1) td:last-child,
.snDataTable table tbody tr:nth-child(2) td:last-child,
.snDataTable table tbody tr:nth-child(4) td:last-child,
.snDataTable table tbody tr:nth-child(6) td:last-child {
  background-color: #a7cbc3 !important;
}

.snDataTable table tbody tr:nth-child(1) td:last-child:hover,
.snDataTable table tbody tr:nth-child(2) td:last-child:hover,
.snDataTable table tbody tr:nth-child(4) td:last-child:hover,
.snDataTable table tbody tr:nth-child(6) td:last-child:hover {
  background-color: #7dafa4 !important;
}

.snDataTable table tbody tr:nth-child(3) td:last-child,
.snDataTable table tbody tr:nth-child(5) td:last-child {
  background-color: #ebc5c5 !important;
}

.snDataTable table tbody tr:nth-child(3) td:last-child:hover,
.snDataTable table tbody tr:nth-child(5) td:last-child:hover {
  background-color: #caa1a1 !important;
}*/

/* structured note part */

.structurenoteDiv {
  width: 100%;
  height: auto;
  margin-top: 8px;
  background-color: #fff;
  box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.6);
}

.summaryPart {
  padding: 5px 10px;
}

.summaryPartTop {
  display: flex;
  justify-content: space-between;
}

.summaryTophead {
  color: #000;
  font-size: 14px;
  font-weight: 700;
}

.summaryTophead span {
  font-weight: 500;
}

.summaryPartRight {
  display: flex;
  align-items: center;
  gap: 10px;
}

.summaryPartRight h5 {
  color: #000;
  font-size: 10px;
}

.summaryDropDiv {
  width: 250px;
  height: 30px;
  /* border: 1px solid #151515; */
  border: 1px solid #cccccc;
  /* padding: 5px 5px 0px; */
  position: relative;
}

.SumDropbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  outline: 0;
  /* border: 1px solid #cccccc; */
  border: none;
  border-bottom: none;
  background-color: #fff;
  color: #151515;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 5px;
  position: relative;
}

.summarydropdown {
  background-color: #fff;
  width: 100%;
  max-height: 120px;
  overflow-y: auto;
  border: 1px solid #cccccc;
  padding: 5px;
  position: absolute;
  bottom: -120px;
  left: 0;
  z-index: 10;
}

.summarydropdown::-webkit-scrollbar {
  width: 5px;
}

.summarydropdown::-webkit-scrollbar-track {
  background: #cccccc;
}

.summarydropdown::-webkit-scrollbar-thumb {
  background: #888;
}

.dropDiv {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 4px 0px;
}

.dropDiv input {
  width: 10px;
  height: 10px;
  accent-color: #151515;
}

.dropDiv p {
  color: #151515;
  font-size: 11px;
  font-weight: 500;
}

.summaryPartBtm {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.summaryCardText {
  width: 48%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.summaryCardDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4edc9;
  padding: 12px 10px;
  border-radius: 7px;
  box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.4);
}

.summaryCardName p {
  color: #151515;
  font-size: 14px;
  font-weight: 500;
}

.summaryCardDate {
  width: 30%;
  text-align: center;
}

.summaryCardDate p {
  color: #9634f3;
  font-size: 17px;
  font-weight: 500;
}

.summaryCardData {
  width: 48%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 70px;
}

.summaryCardDataInn {
  width: 48%;
  position: relative;
  text-align: center;
}

.summaryCardDataInn::after {
  content: "";
  width: 60%;
  height: 2px;
  background-color: #3786d5;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.sumPointName {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.sumDataNum {
  color: #9634f3;
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 8px;
}

/* SN SUMMARY NEW Chart */

/* chart section */

.myChartSec {
  width: 100%;
  height: auto;
  margin-top: 10px;
  /* border-top: 6px solid #92bbac; */
  background-color: #fff;
  position: relative;
  padding-top: 30px;
}

.ChartFilter {
  position: absolute;
  top: 5px;
  right: 30px;
  display: flex;
  /* align-items: center; */
  gap: 5px;
}

.ChartdropBtn {
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f4e0;
  border: 1px solid #858585;
  color: #151515;
  font-size: 11px;
  padding: 3px 5px;
  border-radius: 5px;
  box-shadow: none;
  outline: 0;
}

.ChartdropBtn:hover,
.ChartdropBtn:focus {
  background-color: #f7f4e0;
  color: #151515;
  box-shadow: none;
  outline: 0;
}

.ChartdropBtn::after {
  border: none;
  content: "\f107";
  font-family: fontawesome;
  font-size: 11px;
}

.chartdropmenu {
  width: 150px;
  background-color: #f7f4e0;
  padding: 5px 7px;
  max-height: 120px;
  overflow-y: auto;
}

.chartdropmenu::-webkit-scrollbar {
  width: 2px;
}

.chartdropmenu::-webkit-scrollbar-track {
  background-color: #ccc;
}

.chartdropmenu::-webkit-scrollbar-thumb {
  background-color: #424242;
}

.chartdropmenu input {
  accent-color: #151515;
  width: 12px;
  height: 12px;
}
.chartdropmenu input[type="checkbox"] {
  accent-color: #151515;
  width: 12px;
  height: 12px;
  margin-right: 5px; /* Adjust spacing as needed */
}

.chartDropTxt {
  font-size: 11px;
}

.chartmenuItem {
  font-size: 11px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}

.myChartSec
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-title {
  background-color: transparent !important;
  border: none !important;
}

.myChartSec .apexcharts-tooltip-title {
  margin-bottom: 0 !important;
}

.myChartSec .apexcharts-tooltip-marker {
  background-color: #4472c4 !important;
}

.myChartSec .apexcharts-tooltip-series-group {
  padding: 0 6px !important;
}

.myChartSec .apexcharts-menu-icon {
  display: none;
}

.myChartSec .apexcharts-legend-marker {
  height: 8px !important;
  width: 8px !important;
}

.myChartSec .apexcharts-tooltip {
  border-radius: 0px;
}

.myChartSec .apexcharts-xaxistooltip {
  display: none;
}

.snChartFilter {
  position: absolute;
  right: 10px;
  top: 5px;
}

/* responsive */

@media (max-width: 1399px) {
  .graphInner {
    height: auto;
  }
}

@media (max-width: 1299px) {
  .graphAmtDiv {
    height: 500px;
  }
}

@media (max-width: 1199px) {
  /* overall view page */

  .graphamtFig {
    width: 40px;
    height: 40px;
  }

  /* structured note part */

  .summaryDropDiv {
    width: 170px;
  }

  .sumDataNum {
    font-size: 16px;
  }

  .summaryCardDate p {
    font-size: 16px;
  }

  .summaryTophead {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  /* overall view page */

  .graphamtFig {
    width: 40px;
    height: 40px;
  }

  .invertorFirstRow {
    flex-direction: column;
  }

  .graphAmtSec {
    width: 100%;
  }

  .returnAnDiv {
    width: 100%;
  }

  .apxChrtDiv {
    width: 100%;
  }

  .graphAmtDiv {
    height: 430px;
  }

  .graphInner {
    height: 350px;
  }

  .graphAmtDivInn {
    margin: 15px 0px;
  }

  /* structured note part */

  .summaryPartRight h5 {
    display: none;
  }
}

@media (max-width: 767px) {
  /* header part start */

  .headerUl li a {
    font-size: 12px;
  }

  .headerPart {
    width: 100%;
  }

  .headerPart.sticky {
    width: 100%;
    left: 0;
  }

  .headmenuBtn {
    display: block;
  }

  .sidebarPart {
    left: -150px;
  }

  .sidebarPart.show {
    left: 0;
  }

  .RestPage {
    width: 100%;
  }

  /* structured note part */

  .summaryPartBtm {
    flex-direction: column-reverse;
    gap: 15px;
  }

  .summaryPartTop {
    padding-bottom: 20px;
  }

  .summaryCardText {
    width: 100%;
  }

  .summaryCardData {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }

  .summaryCardDataInn {
    width: 23%;
  }

  .summaryCardDate {
    text-align: end;
  }

  .sumPointName {
    font-size: 13px;
  }

  .sumDataNum {
    font-size: 13px;
  }

  .summaryCardName p {
    font-size: 12px;
  }

  .summaryCardDate p {
    font-size: 13px;
  }

  .summaryCardDiv {
    padding: 6px 10px;
  }
}

@media (max-width: 575px) {
  /* header part */

  .headerPart {
    margin-bottom: 15px;
  }

  .headerUl li {
    width: auto;
  }

  /* overall view page */

  .returnAnDiv .react-tabs__tab-list {
    position: static;
    margin-bottom: 15px;
  }

  .returnAnDiv .react-tabs__tab {
    font-size: 11px;
    padding: 3px 5px;
  }

  .dataTableDiv table th {
    font-size: 11px;
  }

  /* structured note part */

  .summaryPartTop {
    flex-direction: column;
    gap: 10px;
  }

  .dataTableDiv::-webkit-scrollbar {
    display: none;
  }

  .summaryCardDataInn {
    width: 48%;
  }
}

@media (max-width: 480px) {
  /* header part */

  .headerInn {
    gap: 15px;
  }

  .headerUl li a {
    font-size: 11px;
    padding: 5px;
  }

  .headerUl {
    justify-content: flex-start;
    overflow-x: auto;
    gap: 10px;
  }

  .headerUl li {
    min-width: 120px;
    width: 120px;
  }

  .headerUl::-webkit-scrollbar {
    display: none;
  }

  /* overall view page */

  .graphAmtDiv {
    padding: 0px 8px;
  }

  .graphNum {
    font-size: 13px;
  }

  .graphPoint {
    font-size: 12px;
  }

  .graphamtFig {
    width: 30px;
    height: 30px;
  }

  /* investment analysis */

  .eStateTableDiv .react-tabs__tab {
    font-size: 11px;
    padding: 5px;
  }
}

@media (max-width: 420px) {
  .apexVerticalTxt {
    font-size: 9px;
  }

  .returnAnDiv .apexcharts-text tspan {
    font-size: 9px;
  }
}

.apexcharts-legend-series {
  list-style-type: none !important;
}

.custom-tooltip {
  padding: 10px;
  border-radius: 0%;
}
