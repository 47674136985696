* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Header part */

.headerPart {
  width: calc(100% - 150px);
  margin-left: 20px;
  height: auto;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
}

.headerPart.sticky {
  position: fixed;
  top: 0;
  left: 150px;
  right: 0;
  width: calc(100% - 150px);
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.headerInn {
  display: flex;
  justify-content: space-between;
}

.headerInn i {
  color: #000;
  font-size: 15px;
}

.headerUl {
  list-style-type: none;
  display: flex;
  gap: 15px;
}

.headerUl li {
  width: 150px;
  background-color: #fff;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.3);
}

.headerUl li a {
  display: block;
  color: #000;
  font-size: 13px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.headerUl li a:hover {
  font-size: 12px;
}

.headerUl li a.active {
  background-color: #cccccc;
}

.headerUl li:hover {
  background-color: #cccccc;
}

.headmenuBtn {
  display: none;
}

.btmheader {
  padding-bottom: 3px;
}

.headerPara {
  display: flex;
  justify-content: flex-end;
  color: #000;
  font-size: 11px;
  font-weight: 500;
  font-style: italic;
}

/* sidebar */

.sidebarPart {
  width: 150px;
  height: 100vh;
  background-color: #e9e9e9;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  box-shadow: 1px 10px 15px 0px rgba(0, 0, 0, 0.8);
  padding: 10px 15px;
  transition: 0.6s;
}

.Logo {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.Logo img {
  width: 100%;
  height: 100%;
}

.sideTopHead {
  color: #000;
  font-size: 13px;
  text-align: center;
}

.todayDate {
  padding: 30px 0px;
  text-align: center;
}

.datehead {
  color: #605e5c;
  font-size: 12px;
  margin-bottom: 6px;
}

.datepara {
  color: #000;
  font-size: 11px;
  font-weight: 500;
}

.datefilter {
  width: 100%;
  display: block;
  border: none;
  outline: 0;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.3);
  color: #000;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
}

.datefilter:hover {
  color: #000;
}

.DateDrop {
  background-color: #e6e6e6;
  margin: 15px 0px 30px;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.5);
  padding: 5px;
  position: relative;
}

.DateDrop p {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.eraserTooltip {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 3px 0;
  position: absolute;
  top: -30px;
  z-index: 1;
  font-size: 12px;
  left: -40px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
}

.eraserTooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltiptext::before {
  content: "";
  position: absolute;
  bottom: -20px;
  display: block;
  height: 22px;
  left: 50%;
  transform: translateX(-50%);
  border-color: #fffefe transparent transparent transparent;
  border-style: solid;
  border-width: 8px;
}

.DateDrop i {
  color: #858585;
  font-size: 12px;
  opacity: 0;
}

.DateDrop:hover i {
  opacity: 1;
}

.dateselectDiv {
  position: relative;
}

.dateselectBtn {
  background-color: transparent;
  border: 1px solid #fff;
  outline: 0;
  color: #747474;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  padding: 0px 3px;
}

.dateselectBtn i {
  font-size: 11px;
  opacity: 1;
}

.dateSlctDrop {
  padding: 10px 5px;
  position: absolute;
  background: #e9e9e9;
  width: 100%;
  left: 0;
  height: 110px;
  overflow-y: auto;
  z-index: 10;
}

.dateSlctDrop::-webkit-scrollbar {
  display: none;
}

.inputDiv {
  display: flex;
  align-items: baseline;
  gap: 5px;
  margin: 3px 0px;
}

.inputDiv input {
  width: 10px;
  height: 10px;
  accent-color: #000;
}

.inputDiv p {
  font-size: 10px;
}

.sectorFilHead {
  background-color: #efdca9;
  padding: 3px;
  cursor: pointer;
}

.sectorFilHead p {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.FilDropHead {
  font-size: 13px;
  color: #000;
  font-weight: 500;
}

.SecCounFil {
  background-color: #fff;
  margin: 15px 0px 30px;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.5);
  padding: 5px;
  position: relative;
}

.inputPara {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.SecCounFil i {
  color: #858585;
  font-size: 12px;
  opacity: 0;
}

.SecCounFil:hover i {
  opacity: 1;
}

.SecCounDiv {
  position: relative;
}

.SecCounBtn {
  background-color: #f7f4e0;
  border: 1px solid #fff;
  outline: 0;
  color: #747474;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  padding: 0px 3px;
}

.SecCounBtn i {
  font-size: 11px;
  opacity: 1;
}

.SecCounDrop {
  padding: 10px 5px;
  position: absolute;
  bottom: -110px;
  background-color: #f7f4e0;
  width: 100%;
  margin: 0px auto;
  left: 0;
  height: 110px;
  overflow-y: auto;
  z-index: 10;
}

.SecCounDrop::-webkit-scrollbar {
  display: none;
}

/* overall view page start */

.RestPage {
  width: calc(100% - 150px);
  height: auto;
  margin-left: 20px;
  margin-bottom: 50px;
  position: relative;
}

/* graph amount */

.graphPart {
  border-top: 6px solid #92bbac;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  position: relative;
}

.graphAmtSec {
  width: 50%;
  display: flex;
  gap: 10px;
}

.graphAmtDiv {
  width: 50%;
  background-color: #fff;
  box-shadow: 1px 10px 15px 0px #a5a5a5;
  position: relative;
  /* height: 480px; */
  height: auto;
}

.graphInner {
  /* height: 430px; */
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20% 0;
}

.graphAmtDivInn {
  width: 100%;
  position: relative;
  text-align: center;
  margin: 30px 0px;
}

.graphAmtDivInn::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 1px;
  background-color: #2277c4;
}

.graphPoint {
  font-size: 14px;
  color: #424242;
}

.graphNum {
  font-size: 15px;
  color: #9634f3;
  margin: 5px 0px;
}

.graphamtFig {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  width: 63px;
}

.graphamtFig img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* graph progress bar */

.overallchart {
  width: 60%;
}

.overallChartTop {
  background-color: #fff;
  box-shadow: 1px 10px 15px 0px #a5a5a5;
  padding: 5px 20px 5px 5px;
  margin-top: 5px;
  overflow: auto;
  height: 600px;
}

.overallChartHead {
  font-size: 14px;
  color: #5a5a5a;
  margin-bottom: 5px;
}

.overallChartUl {
  list-style-type: none;
  padding-top: 15px;
}

.overallChartUl li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}

.chartLiLeft {
  width: 22%;
  position: relative;
  display: inline-block;
}

.chartLiTxt {
  font-size: 11px;
  font-weight: 500;
  text-align: end;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ChartLitooltip {
  position: absolute;
  left: 100%;
  top: 100%;
  width: auto;
  border: 1px solid black;
  background-color: #f1f0f0;
  z-index: 10;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #000;
  width: 100%;
  text-align: center;
  visibility: hidden;
}

.chartLiLeft:hover .ChartLitooltip {
  visibility: visible;
}

.chartLiRight {
  width: 78%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.progresstooltip {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -130px;
  z-index: 10;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  width: auto;
  height: auto;
  padding: 5px 10px;
  gap: 15px;
  visibility: hidden;
}

.progresstooltip::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  display: block;
  height: 20px;
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent transparent #fffefe;
}

.chartLiRight:hover .progresstooltip {
  visibility: visible;
}

.progresstooltip ul {
  text-decoration: none;
}

.progresstooltip ul li {
  color: #151515;
  font-size: 11px;
  font-weight: 500;
}

.overallChartTop .progress {
  background-color: #fff;
  border-radius: 0;
}

.overallChartTop .progress-bar {
  background-color: #f5c4af;
  position: relative;
}

.Chartspan {
  position: absolute;
  right: 5px;
  color: #000;
  font-size: 10px;
  font-weight: 500;
}

/* amount due analysis */

.overallChartBtm {
  background-color: #fff;
  box-shadow: 1px 10px 15px 0px #a5a5a5;
  padding: 5px 20px 5px 5px;
  margin-top: 15px;
  position: relative;
}

.amtDueBtnDiv {
  display: flex;
  gap: 8px;
}

.amtDueBtn {
  background-color: transparent;
  border: none;
  outline: 0;
  font-size: 11px;
  font-weight: 500;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  gap: 2px;
}

.amtBlankDiv {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.overallChartBtm .progress {
  /* background-color: #92bbac; */
  background-color: #338f86;
  border-radius: 0;
  position: relative;
  overflow: visible;
}

.overallChartBtm .progress-bar {
  /* background-color: #f0e199; */
  background-color: #f5bd20;
  position: relative;
  overflow: visible;
}

.progInntooltip {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -100px;
  z-index: 15;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  width: auto;
  height: auto;
  padding: 5px 10px;
  gap: 15px;
  display: inline-block;
  visibility: hidden;
}

.progInntooltip::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  display: block;
  height: 20px;
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent transparent #fffefe;
}

.progInntooltip ul {
  text-decoration: none;
}

.progInntooltip ul li {
  color: #151515;
  font-size: 11px;
  font-weight: 500;
  margin: 20px 0px;
}

.overallChartBtm .progress-bar:hover .progInntooltip {
  visibility: visible;
}

.barspan {
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
  color: #000;
  font-size: 9px;
  font-weight: 500;
}

.progspan {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3px;
  color: #000;
  font-size: 9px;
  font-weight: 500;
}

/* horizontal bar */

.overallchart .apexcharts-legend {
  top: 20px !important;
}

.overallchart .apexcharts-tooltip {
  min-width: 40%;
  background-color: #fff;
  border-radius: 0px;
  border: none !important;
  box-shadow: 0 0 5px 0 #0000004d;
  padding: 5px 8px;
  white-space: wrap;
}

.overallchart .apexcharts-tooltip-title {
  background: #fff !important;
  border: none !important;
  margin: 0;
}

.arrow_box {
  color: #151515;
  font-size: 10.5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  gap: 3px;
}

.headspan {
  font-weight: 300;
  width: 57%;
}

.paraspan {
  width: 68%;
  text-align: start;
}

/* chart section */

.myChartSec {
  width: 100%;
  height: auto;
  margin-top: 10px;
  border-top: 6px solid #92bbac;
  background-color: #fff;
  position: relative;
}

.ChartFilter {
  position: absolute;
  top: 5px;
  right: 30px;
  display: flex;
  /* align-items: center; */
  gap: 5px;
}

.ChartdropBtn {
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f4e0;
  border: 1px solid #858585;
  color: #151515;
  font-size: 11px;
  padding: 3px 5px;
  border-radius: 5px;
  box-shadow: none;
  outline: 0;
}

.ChartdropBtn:hover,
.ChartdropBtn:focus {
  background-color: #f7f4e0;
  color: #151515;
  box-shadow: none;
  outline: 0;
}

.ChartdropBtn::after {
  border: none;
  content: "\f107";
  font-family: fontawesome;
  font-size: 11px;
}

.chartdropmenu {
  width: 150px;
  background-color: #f7f4e0;
  padding: 5px 7px;
  max-height: 120px;
  overflow-y: auto;
}

.chartdropmenu::-webkit-scrollbar {
  width: 2px;
}

.chartdropmenu::-webkit-scrollbar-track {
  background-color: #ccc;
}

.chartdropmenu::-webkit-scrollbar-thumb {
  background-color: #424242;
}

.chartdropmenu input {
  accent-color: #151515;
  width: 12px;
  height: 12px;
}
.chartdropmenu input[type="checkbox"] {
  accent-color: #151515;
  width: 12px;
  height: 12px;

  margin-right: 5px; /* Adjust spacing as needed */
}

.chartDropTxt {
  font-size: 11px;
}

.chartmenuItem {
  font-size: 11px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}

.myChartSec
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-title {
  background-color: transparent !important;
  border: none !important;
}

.myChartSec .apexcharts-tooltip-title {
  margin-bottom: 0 !important;
}

.myChartSec .apexcharts-tooltip-marker {
  background-color: #92bbac !important;
}

.myChartSec .apexcharts-tooltip-series-group {
  padding: 0 6px !important;
}

.myChartSec .apexcharts-menu-icon {
  display: none;
}

.myChartSec .apexcharts-legend-marker {
  height: 8px !important;
  width: 8px !important;
}

.myChartSec .apexcharts-tooltip {
  border-radius: 0px;
}

.myChartSec .apexcharts-xaxistooltip {
  display: none;
}

/* overall view page end */

/* Repayment page start */

.repayDataTable {
  width: 100%;
  border: 1px solid #338e85;
  padding: 10px 4px 4px;
  background-color: #fff;
  position: relative;
  border-top: 6px solid #92bbac;
}

.repayDataTable .react-tabs__tab-list {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  list-style-type: none;
  padding: 15px 50px 15px 0px;
}

.repayDataTable .react-tabs__tab {
  background-color: #f9f5c4;
  color: #151515;
  font-size: 12px;
  font-weight: 500;
  width: 140px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  position: relative;
}

.RepayTooltip {
  position: absolute;
  top: -45px;
  left: 0;
  z-index: 10;
  background-color: #fff;
  color: #747474;
  padding: 8px 10px;
  font-size: 11px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  visibility: hidden;
}

.RepayTooltip::after {
  content: "";
  position: absolute;
  bottom: -20px;
  display: block;
  height: 22px;
  left: 50%;
  transform: translateX(-50%);
  border-color: #fffefe transparent transparent transparent;
  border-style: solid;
  border-width: 12px;
}

.repayDataTable .react-tabs__tab:hover {
  background-color: #cccccc;
  font-size: 11px;
}

.repayDataTable .react-tabs__tab:hover .RepayTooltip {
  visibility: visible;
}

.RepayTableHead {
  color: #595959;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.DataTableInn {
  width: 100%;
  max-height: 100vh;
  overflow: auto;
}

.DataTableInn::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.DataTableInn::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.DataTableInn::-webkit-scrollbar-thumb {
  background-color: #747474;
  border-radius: 10px;
}

/* .DataTableInn table {
      border-collapse: collapse;
      width: 100%;
      table-layout: fixed;
  }
  
  td,th {
      padding: 5px;
      min-width: 200px;
  }
  
  thead tr {
      display: block;
      position: relative;
  }
  
  tbody {
      display: block;
      height: 200px;
      width: 100%;
      overflow-y: auto;
  } */

.DataTableInn th {
  background-color: #338f86;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border-right: 1px solid #fff;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 15px;
  position: relative;
}

.DataTableInn th i {
  position: absolute;
  left: 5px;
  bottom: 3px;
  color: #fff;
  font-size: 12px;
  opacity: 0;
}

.DataTableInn th:hover {
  background-color: #1f7068;
}

.DataTableInn th:hover i {
  opacity: 1;
}

.DataTableInn td {
  font-size: 11px;
  padding: 5px 5px;
  color: #151515;
  font-weight: 500;
  border: 1px solid #e5e5e5;
  text-align: center;
  white-space: nowrap;
  min-width: 130px;
}

/* active payment */

.activePayTable tr:nth-child(even) {
  background-color: #eeeded;
}

.activePayTable tr:hover {
  background-color: #eeeded;
}

.activePayTable td:hover {
  background-color: #cccccc;
}

.activePayTable table tbody tr td:nth-child(6) {
  background-color: #a7cbc3;
}

.activePayTable table tbody tr td:nth-child(6):hover {
  background-color: #7dafa4;
}

.activePayTable table tbody tr td:nth-child(10) {
  background-color: rgb(235, 197, 197);
}

.activePayTable table tbody tr td:nth-child(10):hover {
  background-color: rgb(202, 161, 161);
}

/* closed payment */

.closePayTable tr:nth-child(even) {
  background-color: #eeeded;
}

.closePayTable tr:hover {
  background-color: #eeeded;
}

.closePayTable td:hover {
  background-color: #cccccc;
}

/* default payment */

.defaultPayTable tr:nth-child(even) {
  background-color: #eeeded;
}

.defaultPayTable table tbody tr td:nth-child(6) {
  background-color: rgb(235, 197, 197);
}

.defaultPayTable table tbody tr td:nth-child(6):hover {
  background-color: rgb(202, 161, 161);
}

.defaultPayTable table tbody tr td:nth-child(11) {
  background-color: #a7cbc3;
}

.defaultPayTable table tbody tr td:nth-child(11):hover {
  background-color: #7dafa4;
}

/* Repayment page end */

/* Responsive */

@media (max-width: 1199px) {
  /* overall view page */

  .graphPoint {
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  /* overall view page */

  .chartLiLeft {
    width: 30%;
  }

  .chartLiRight {
    width: 70%;
  }

  .graphNum {
    font-size: 14px;
  }

  .progresstooltip {
    top: -55px;
    transform: translateX(-50%);
    left: 10px;
  }

  .progresstooltip::before {
    display: none;
  }

  .progresstooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 20px;
    border-width: 10px;
    border-style: solid;
    border-color: #fffefe transparent transparent transparent;
  }

  .graphPart {
    flex-direction: column;
  }

  .graphAmtSec {
    width: 100%;
  }

  .overallchart {
    width: 100%;
  }

  .graphInner {
    padding: 0px;
  }

  .graphAmtDivInn {
    margin: 20px 0px;
  }

  .graphAmtDiv {
    height: 420px;
  }
}

@media (max-width: 767px) {
  /* header part start */

  .headerUl li a {
    font-size: 12px;
  }

  .headerPart {
    width: 100%;
  }

  .headerPart.sticky {
    width: 100%;
    left: 0;
  }

  .headmenuBtn {
    display: block;
  }

  .sidebarPart {
    left: -150px;
  }

  .sidebarPart.show {
    left: 0;
  }

  /* overall view page */

  .RestPage {
    width: 100%;
  }

  /* Repayment page */

  .repayDataTable .react-tabs__tab-list {
    padding: 10px 10px 10px 0px;
    gap: 15px;
  }

  .DataTableInn td {
    min-width: auto;
  }
}

@media (max-width: 575px) {
  /* header part */

  .headerPart {
    margin-bottom: 15px;
  }

  .headerUl li {
    width: auto;
  }

  /* overall view page */

  .chartDropTxt {
    display: none;
  }

  .ChartFilter {
    right: 10px;
  }

  .overallChartBtm {
    padding: 35px 5px 5px;
  }

  .overallChartBtm .ChartdropBtn {
    width: 90px;
    padding: 3px;
    font-size: 11px;
  }

  .overallChartBtm .ChartFilter {
    left: 10px;
    top: 5px;
  }

  .overallChartBtm .chartdropmenu {
    width: 90px;
    padding: 5px 3px;
  }

  /* Repayment page */

  .repayDataTable .react-tabs__tab-list {
    overflow-x: auto;
    justify-content: flex-start;
    gap: 10px;
    padding: 0px 0px 10px 0px;
  }

  .repayDataTable .react-tabs__tab-list::-webkit-scrollbar {
    display: none;
  }

  .repayDataTable .react-tabs__tab {
    min-width: 120px;
    padding: 3px 5px;
  }

  .DataTableInn th {
    font-size: 11px;
    padding: 5px 12px;
  }

  .RepayTableHead {
    font-size: 12px;
  }

  .DataTableInn th i {
    left: 3px;
    bottom: 0px;
  }
}

@media (max-width: 480px) {
  /* header part */

  .headerUl li a {
    font-size: 11px;
  }

  /* overall view page */

  .overallChartHead {
    font-size: 12px;
  }

  .ChartdropBtn {
    width: 120px;
  }

  .chartdropmenu {
    width: 120px;
  }

  .chartmenuItem {
    font-size: 10px;
  }

  .graphAmtDiv {
    height: 340px;
  }

  .graphAmtDivInn {
    margin: 10px 0px;
  }

  .graphamtFig {
    width: 40px;
  }

  .graphPoint {
    font-size: 12px;
  }

  .graphNum {
    font-size: 13px;
  }

  .overallChartHead {
    font-size: 12px;
  }

  /* Repayment page */
}

/* Enable horizontal scrolling */

.tableWrapper {
  overflow-x: auto;
}
.tableWrapper th:first-child,
.tableWrapper td:first-child {
  position: sticky !important;
  left: 0;
  z-index: 100;
  min-width: 180px;
}
.tableWrapper th:nth-child(2),
.tableWrapper td:nth-child(2) {
  position: sticky !important;
  left: 168px;
  z-index: 100;
}

.tableWrapper td:first-child,
.tableWrapper td:nth-child(2) {
  background-color: white;
}
